html,
body {
  font-family: "Roboto", sans-serif !important;
  background: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
/* 
h4 {
  color: #999 !important;
  font-weight: 400 !important;
}
.nav-container {
  margin: auto;
}
.nav-container svg {
  color: #fff;
}
a {
  text-decoration: none;
}

input {
} */

.pw-input {
  border: 2px solid #eee;
  padding: 0.25em 2em 0.25em 0.5em !important;
}

.ant-input-affix-wrapper,
.ant-dropdown-trigger {
  margin-right: 0.5em;
}
/* UI */
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

label,
.clickable {
  cursor: pointer;
}

.spin {
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

.spin-large{
  width: 5em !important;
  height: auto !important;
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.shadow {
  border-collapse: separate;
  -webkit-box-shadow: -5px 5px 20px 5px rgba(215, 215, 215, 1);
  -moz-box-shadow: -5px 5px 20px 5px rgba(215, 215, 215, 1);
  box-shadow: -5px 5px 20px 5px rgba(215, 215, 215, 1);
}
.floating-component-parent {
  padding: 2em;
  padding-bottom: 0;
  position: relative;
  flex-grow: 1;
}

.floating-component {
  display: block;
  margin-bottom: 2em;
  padding: 1.71em 0;
  background: #fff;
  border-radius: 0.5em;
}

.full-width {
  width: 100%;
}
.hide {
  display: none;
}

.errors {
  display: block;
  color: red;
  font-size: 0.85em;
}

.padding-normal {
  padding-left: 1.71em;
  padding-right: 1.71em;
}

.em2 {
  font-weight: 700;
  font-size: 1.5em !important;
}
.em3 {
  font-weight: 400;
  font-size: 1em;
  color: #7e7e7e !important;
}
.em4-light {
  font-weight: 400;
  font-size: 1em;
  color: #8c8c8c !important;
}
.em4-dark {
  font-weight: 400;
  font-size: 1em;
  color: #595959 !important;
}
/* Positioning */
.center {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.valign {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.halign {
  position: absolute !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}
