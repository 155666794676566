body{
  background: #fff;
}

.login-form{
  background: #f3f3f3;
  border-radius: 0.5em;
  padding:4.6em 2.9em 3.2em 3.2em !important;
  text-align: left;
}

.logo-box {
  height: 3em;
  margin: 1.5em;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  left: 1em;
  top: 0.75em;
}

.login-title{
  font-size: 1.7em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
}

.pw-field-wrapper{
  position: relative;
  color: rgba(0, 0, 0, 0.85);
}

.ant-form-row-login{
  align-items: center;
}
.ant-form-row-login,.ant-form-item-has-error{
  align-items: flex-start;
}
.pw-toggle{
  cursor: pointer;
  right: 0.7em;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}
.pw-errors{
  width: 100%;
  text-align: center;
}

.pw-input{
}
.login-button{
  justify-content: center;
}

.select-user{
  cursor: pointer;
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}

