.search-result{
  color: inherit;
  width: 100%;
  border-top: 1px solid #f3f3f3;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start; 
  padding: 1em;
}
.search-result:hover{
  color: inherit;
}

.search-result-title{
  font-weight: 500;
}
.search-result-content{
  color:#000 !important;
}

.search-main-box{
  display: flex;
  flex-direction: column;
}

.search-main-box > span{
  word-wrap: anywhere;
}

.search-result-breadcrumb{
  padding-top: 0.25em;
  padding-bottom: 0.5em
}
