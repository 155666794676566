/* ============================================================================================== */
/* MODAL CONTENT STYLES */
/* ============================================================================================== */
.modal-form
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.modal-form
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.modal-form.ant-form-vertical .ant-col.ant-form-item-label {
  padding: 0;
  margin-bottom: 2px;
}

.modal-form.ant-form-vertical .ant-form-item {
  margin: 16px 0 0 0;
}

.modal-form.ant-form-vertical .ant-form-item:first-of-type {
  margin-top: 0;
}

.modal-form .ant-col {
  width: 100%;
}

/* ============================================================================================== */
/* MODAL FOOTER STYLES */
/* ============================================================================================== */
.modal .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.modal .ant-modal-footer .modal-footer-filler {
  flex: 1 0 auto;
}

.modal .ant-modal-footer .modal-delete-confirmation {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: #ff4d4f;
  margin-bottom: 0;
}
