/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'), url(/static/media/robotoi1.f8b1df51.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'), url(/static/media/robotoi1.f8b1df51.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'), url(/static/media/robotoi1.f8b1df51.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'), url(/static/media/robotoi1.f8b1df51.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'), url(/static/media/robotoi1.f8b1df51.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'), url(/static/media/robotoi1.f8b1df51.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'), url(/static/media/robotoi1.f8b1df51.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi3.14286f3b.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi3.14286f3b.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi3.14286f3b.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi3.14286f3b.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi3.14286f3b.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi3.14286f3b.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi3.14286f3b.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi4.51521a2a.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi4.51521a2a.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi4.51521a2a.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi4.51521a2a.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi4.51521a2a.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi4.51521a2a.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi4.51521a2a.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi5.db4a2a23.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi5.db4a2a23.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi5.db4a2a23.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi5.db4a2a23.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi5.db4a2a23.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi5.db4a2a23.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium Italic'), local('Roboto-LightItalic'), url(/static/media/robotoi5.db4a2a23.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url(/static/media/robotoi7.da0e7178.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url(/static/media/robotoi7.da0e7178.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url(/static/media/robotoi7.da0e7178.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url(/static/media/robotoi7.da0e7178.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url(/static/media/robotoi7.da0e7178.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url(/static/media/robotoi7.da0e7178.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url(/static/media/robotoi7.da0e7178.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin'), local('Roboto-Thin'), url(/static/media/robotoi1.f8b1df51.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin'), local('Roboto-Thin'), url(/static/media/roboto1.7370c367.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin'), local('Roboto-Thin'), url(/static/media/roboto1.7370c367.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin'), local('Roboto-Thin'), url(/static/media/roboto1.7370c367.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin'), local('Roboto-Thin'), url(/static/media/roboto1.7370c367.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin'), local('Roboto-Thin'), url(/static/media/roboto1.7370c367.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('Roboto Thin'), local('Roboto-Thin'), url(/static/media/roboto1.7370c367.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light'), local('Roboto-Light'), url(/static/media/roboto3.ef7c6637.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light'), local('Roboto-Light'), url(/static/media/roboto3.ef7c6637.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light'), local('Roboto-Light'), url(/static/media/roboto3.ef7c6637.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light'), local('Roboto-Light'), url(/static/media/roboto3.ef7c6637.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light'), local('Roboto-Light'), url(/static/media/roboto3.ef7c6637.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light'), local('Roboto-Light'), url(/static/media/roboto3.ef7c6637.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'), url(/static/media/roboto4.479970ff.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'), url(/static/media/roboto4.479970ff.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'), url(/static/media/roboto4.479970ff.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'), url(/static/media/roboto4.479970ff.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'), url(/static/media/roboto4.479970ff.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'), url(/static/media/roboto4.479970ff.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'), url(/static/media/roboto4.479970ff.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(/static/media/roboto5.020c97dc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(/static/media/roboto5.020c97dc.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(/static/media/roboto5.020c97dc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(/static/media/roboto5.020c97dc.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(/static/media/roboto5.020c97dc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(/static/media/roboto5.020c97dc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(/static/media/roboto5.020c97dc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(/static/media/roboto7.2735a3a6.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(/static/media/roboto7.2735a3a6.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(/static/media/roboto7.2735a3a6.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(/static/media/roboto7.2735a3a6.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(/static/media/roboto7.2735a3a6.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(/static/media/roboto7.2735a3a6.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(/static/media/roboto7.2735a3a6.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Roboto Black'), local('Roboto-Black'), url(/static/media/roboto9.9b3766ef.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Roboto Black'), local('Roboto-Black'), url(/static/media/roboto9.9b3766ef.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Roboto Black'), local('Roboto-Black'), url(/static/media/roboto9.9b3766ef.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Roboto Black'), local('Roboto-Black'), url(/static/media/roboto9.9b3766ef.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Roboto Black'), local('Roboto-Black'), url(/static/media/roboto9.9b3766ef.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Roboto Black'), local('Roboto-Black'), url(/static/media/roboto9.9b3766ef.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Roboto Black'), local('Roboto-Black'), url(/static/media/roboto9.9b3766ef.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


html,
body {
  font-family: "Roboto", sans-serif !important;
  background: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
/* 
h4 {
  color: #999 !important;
  font-weight: 400 !important;
}
.nav-container {
  margin: auto;
}
.nav-container svg {
  color: #fff;
}
a {
  text-decoration: none;
}

input {
} */

.pw-input {
  border: 2px solid #eee;
  padding: 0.25em 2em 0.25em 0.5em !important;
}

.ant-input-affix-wrapper,
.ant-dropdown-trigger {
  margin-right: 0.5em;
}
/* UI */
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

label,
.clickable {
  cursor: pointer;
}

.spin {
  -webkit-animation-name: spin;
          animation-name: spin;
  -webkit-animation-duration: 3000ms;
          animation-duration: 3000ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; 
}

.spin-large{
  width: 5em !important;
  height: auto !important;
}
@-webkit-keyframes spin {
    from {
        -webkit-transform:rotate(0deg);
                transform:rotate(0deg);
    }
    to {
        -webkit-transform:rotate(360deg);
                transform:rotate(360deg);
    }
}
@keyframes spin {
    from {
        -webkit-transform:rotate(0deg);
                transform:rotate(0deg);
    }
    to {
        -webkit-transform:rotate(360deg);
                transform:rotate(360deg);
    }
}

.shadow {
  border-collapse: separate;
  box-shadow: -5px 5px 20px 5px rgba(215, 215, 215, 1);
}
.floating-component-parent {
  padding: 2em;
  padding-bottom: 0;
  position: relative;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.floating-component {
  display: block;
  margin-bottom: 2em;
  padding: 1.71em 0;
  background: #fff;
  border-radius: 0.5em;
}

.full-width {
  width: 100%;
}
.hide {
  display: none;
}

.errors {
  display: block;
  color: red;
  font-size: 0.85em;
}

.padding-normal {
  padding-left: 1.71em;
  padding-right: 1.71em;
}

.em2 {
  font-weight: 700;
  font-size: 1.5em !important;
}
.em3 {
  font-weight: 400;
  font-size: 1em;
  color: #7e7e7e !important;
}
.em4-light {
  font-weight: 400;
  font-size: 1em;
  color: #8c8c8c !important;
}
.em4-dark {
  font-weight: 400;
  font-size: 1em;
  color: #595959 !important;
}
/* Positioning */
.center {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
      -ms-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
}

.valign {
  position: absolute !important;
  top: 50% !important;
  -webkit-transform: translateY(-50%) !important;
      -ms-transform: translateY(-50%) !important;
          transform: translateY(-50%) !important;
}

.halign {
  position: absolute !important;
  left: 50% !important;
  -webkit-transform: translateX(-50%) !important;
      -ms-transform: translateX(-50%) !important;
          transform: translateX(-50%) !important;
}

/* ============================================================================================== */
/* HTML TEXT STYLES */
/* ============================================================================================== */
html,
body {
  font-family: "Roboto", sans-serif !important;
  background: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
} */

/* h4 {
  color: #999 !important;
  font-weight: 400 !important;
} */

.nav-container {
  margin: auto;
}
.nav-container svg {
  color: #fff;
}
a {
  text-decoration: none;
}

input {
}

.logo-box {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
}

.logo {
  width: auto;
  height: 100%;
}

.site-layout .site-layout-background {
  background: #fff;
}

hr.folder-hr{
  border: 1px solid #ABABAB;
  margin: 1em;
}

.resources-topbar{
  background: #fff;
  padding: 1em 1.71em;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
span.em4-light:last-of-type > a{
  color: #595959 !important;
}

.resources-topbar-right{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 1em;
}

@media only screen and (orientation: landscape) {
  .resources-search {
    width: 18em !important;
  }
}


.button-holder{
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.button-holder > .ant-btn{
  margin: 0.25em !important;
}

.folder-blank > .ant-btn, .folder-blank > .add-blank{
  margin: 1em;
}
.folder-topbar{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #f3f3f3;
  padding: 0 1.71em;
  padding-bottom: 0.89em;
  margin-bottom: 1.71em;
}

.folder-post{
  position: relative;
  overflow-x: scroll;
}

.folder-blank{
  display:-webkit-flex;
  display:-ms-flexbox;
  display:flex;
  padding: 5em 0;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.post-topbar{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.folder-holder{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
  padding: 0 1.71em;
}

.post-editbar{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  border-bottom: 1px solid #f3f3f3;
  padding: 0 1.71em;
  padding-bottom: 0.89em;
  margin-bottom: 1.71em;
}

.folder-editbar{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.folder-post{
  padding: 0 1.71em;
}

.folder-loading{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.move-dialog > .ant-modal-content > .ant-modal-body{
  padding: 0;
}
.move-modal-top>.anticon{
  padding-right: 1em;
}

.navpath-list{
  width: 100%;
  padding: 0.75em 1.5em;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor:pointer;
}

.navpath-list-enabled:hover{
  background: #1890ff;
  font-weight: bold;
  color: white;
}
.navpath-list-disabled{
  background: #eee;
  color: #555;
  cursor: not-allowed;
}
.navpath-list-left{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.navpath-list-empty{
  width: 100%;
  text-align: center;
  padding: 2em;
}
.navpath-list-left > .anticon-folder > svg{
  width: 2.5em !important;
  height: 2.5em !important;
  padding-right: 0.75em;
}
.navpath-list-enabled > .navpath-list-left > .anticon-folder > svg{
  color: #92ceff !important;
}
.navpath-list-disabled > .navpath-list-right > .anticon-right > svg{
  display:none;
}
.navpath-list-enabled > .navpath-list-right > .anticon-right > svg{
  color: #fff !important;
}


.folder{
  margin: 0.5em;
  border-radius: 0.5em;
  padding: 0.25em 1em;
  width: calc( 25% - 1em);
  min-width: 5em;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.folder-link{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.folder-normal{
  color: black;
  border: 1px solid #C4C4C4;
}
.folder-normal:hover{
  background: #f3f3f3;
}

.folder-label{
  max-height: 1.5em;
  overflow: hidden;
  word-wrap: anywhere;
}
.folder-selected{
  background: #e9f0fd;
  border: 1px solid #3068cb;
  color: #3068cb;
  cursor: pointer;
}
.folder-selected:hover{
  box-shadow: inset 0px 0px 100px 100px rgba(248,248,248,0.5);
}

.folder-logo-icon{
  line-height: 1;
  color: #92ceff !important;
}
.folder-logo-icon > .anticon-folder > svg{
  width: 2.5em !important;
  height: 2.5em !important;
  padding-right: 0.5em;
}

.folder-normal:hover, .folder-label:hover{
  color: black !important;
}


.folder-add{
  color: #1890ff;
  border: 1px solid #1890ff;
}
.folder-add:hover{
  background: #f3f3f3;
}
.folder-logo-plus{
  font-size: 2.5em;
  line-height: 1;
  padding-right: 0.25em;
}
.fit{
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.folder-create-bg{
  position: fixed;
  z-index: 5;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(128,128,128,0.5);
  color: black;
  margin: 0
}

.folder-create{
  position: fixed;
  top: 0;
  width: 50%;
  background: #fff;
}

.folder-create-input{
  width: 100%;
}

.folder-create-topbar{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 1em 2em;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: bold;
  border-bottom : 1px solid #f3f3f3;
}
.folder-create-middlebar{
  padding: 2em;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.folder-create-endbar{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-top : 1px solid #f3f3f3;
  padding: 1em 2em;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.folder-create-endbar > .ant-btn{
  margin-left: 1em;
}

.search-result{
  color: inherit;
  width: 100%;
  border-top: 1px solid #f3f3f3;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start; 
  padding: 1em;
}
.search-result:hover{
  color: inherit;
}

.search-result-title{
  font-weight: 500;
}
.search-result-content{
  color:#000 !important;
}

.search-main-box{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

.search-main-box > span{
  word-wrap: anywhere;
}

.search-result-breadcrumb{
  padding-top: 0.25em;
  padding-bottom: 0.5em
}

body{
  background: #fff;
}

.login-form{
  background: #f3f3f3;
  border-radius: 0.5em;
  padding:4.6em 2.9em 3.2em 3.2em !important;
  text-align: left;
}

.logo-box {
  height: 3em;
  margin: 1.5em;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  left: 1em;
  top: 0.75em;
}

.login-title{
  font-size: 1.7em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
}

.pw-field-wrapper{
  position: relative;
  color: rgba(0, 0, 0, 0.85);
}

.ant-form-row-login{
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.ant-form-row-login,.ant-form-item-has-error{
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.pw-toggle{
  cursor: pointer;
  right: 0.7em;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}
.pw-errors{
  width: 100%;
  text-align: center;
}

.pw-input{
}
.login-button{
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.select-user{
  cursor: pointer;
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}


/* ============================================================================================== */
/* MODAL CONTENT STYLES */
/* ============================================================================================== */
.modal-form
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.modal-form
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.modal-form.ant-form-vertical .ant-col.ant-form-item-label {
  padding: 0;
  margin-bottom: 2px;
}

.modal-form.ant-form-vertical .ant-form-item {
  margin: 16px 0 0 0;
}

.modal-form.ant-form-vertical .ant-form-item:first-of-type {
  margin-top: 0;
}

.modal-form .ant-col {
  width: 100%;
}

/* ============================================================================================== */
/* MODAL FOOTER STYLES */
/* ============================================================================================== */
.modal .ant-modal-footer {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.modal .ant-modal-footer .modal-footer-filler {
  -webkit-flex: 1 0 auto;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}

.modal .ant-modal-footer .modal-delete-confirmation {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: #ff4d4f;
  margin-bottom: 0;
}

/* ============================================================================================== */
/* GENERAL LAYOUT STYLES */
/* ============================================================================================== */

.page-container {
  height: 100%;
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

.vertical-fill-layout {
  padding: 0 24px;
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
}

/* ============================================================================================== */
/* HEADER LAYOUT STYLES */
/* ============================================================================================== */
.page-header-container {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  height: 98px;
  padding: 16px 24px;
  background-color: white;
}

.page-header-main-items {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.page-header-main-items h1 {
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.page-header-interactive-items .ant-input-affix-wrapper,
.page-header-interactive-items button {
  margin-left: 8px;
  margin-right: 0;
}

/* ============================================================================================== */
/* CONTENT CARD/CONTAINER STYLES */
/* ============================================================================================== */

.content-card {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin: 24px 0 0 0;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
}

.content-card.img-banner {
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  height: 200px;
}

.img-banner {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.img-banner h1 {
  position: absolute;
  bottom: 0px;
  left: 24px;
  color: white;
  font-weight: 500;
  font-size: 36px;
  margin-bottom: 0;
  /* text-shadow: 0 0 5px black; */
}

.img-banner button {
  position: absolute;
  top: 10px;
  right: 24px;
  color: grey;
  font-size: 14px;
}

.img-banner-mask {
  position: absolute;
  background-color: rgba(105, 105, 105, 0.5);
  width: 100%;
  height: 100%;
}

.img-banner img {
  /* width: 100%; */
  /* flex-shrink: 1; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ============================================================================================== */
/* CONTENT LAYOUTS AND STYLES */
/* ============================================================================================== */
.content-card-header {
  padding: 24px 24px 0 24px;
  border-bottom: 2px solid #f0f0f0;
}

.content-card-header h1 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.content-card-header h1.bold {
  font-weight: 500;
}

.content-card-header .header-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 20px;
}

.content-card ul {
  list-style-type: none;
  padding: 0;
}

.content-card-list ul,
ul.content-card-list {
  margin: 0 24px;
  padding: 0;
  list-style-type: none;
}

/* ============================================================================================== */
/* ANNOUNCEMENT LIST STYLES */
/* ============================================================================================== */
.announcement-list .ant-pagination {
  text-align: center;
  margin-bottom: 15px;
}

.announcement-list .ant-pagination li:last-child {
  margin-bottom: 0;
}

/* ============================================================================================== */
/* ANNOUNCEMENT ITEM STYLES */
/* ============================================================================================== */
.ant-list-lg .ant-list-item.announcement-item {
  position: relative;
  padding: 20px 0;
  /* margin: 0 24px; */
}

.announcement-header {
  padding: 0;
  margin-bottom: 8px;
}

.announcement-title {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 22px;
}

.announcement-header h1,
.announcement-header h3 {
  display: inline-block;
  margin-bottom: 0;
  line-height: 22px;
}

.announcement-header h1 {
  font-size: 14px;
  font-weight: 500;
  color: #595959;
}

.announcement-header h3 {
  font-size: 12px;
  font-weight: 300;
  color: #595959;
  display: inline-block;
  margin-right: 1em;
}

.announcement-header .anticon {
  color: #8c8c8c;
}

.announcement-content-container-holder {
  position: relative;
}

.announcement-content-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.announcement-content {
  width: 100%;
  color: #595959;
}

/* Handling Item Collapse */
.announcement-content.overflowed {
  max-height: 400px;
  overflow: hidden;
}

.announcement-expand-toggle-checkbox {
  display: none;
}

.announcement-expand-toggle,
.announcement-collapse-toggle {
  display: none;
  width: 100%;
  text-align: center;
}

.announcement-expand-toggle label,
.announcement-collapse-toggle label {
  color: grey;
}

.announcement-expand-toggle {
  position: absolute;
  bottom: 0;
  padding: 100px 0 10px 0;
  background-image: linear-gradient(rgba(255, 255, 255, 0), white);
}

.announcement-collapse-toggle {
  display: none;
  position: relative;
  padding-top: 10px;
}

.announcement-content.overflowed ~ .announcement-expand-toggle {
  display: block;
}

.announcement-expand-toggle-checkbox:checked ~ .announcement-expand-toggle {
  display: none;
}

.announcement-expand-toggle-checkbox:checked ~ .announcement-collapse-toggle {
  display: block;
}

.announcement-expand-toggle-checkbox:checked
  ~ .announcement-content.overflowed {
  max-height: 1600px;
  overflow: scroll;
}

.ckeditor-content p {
  font-size: 14px;
}

/* ============================================================================================== */
/* GENERAL CONTACT STYLES*/
/* ============================================================================================== */
.contact-item:last-child {
  margin-bottom: 12px;
}

/* ============================================================================================== */
/* CONTACT LIST AND ITEM STYLES*/
/* ============================================================================================== */
.contact-item {
  width: 100%;
}

.contact-item-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 2px solid #f0f0f0;
  margin: 0 24px;
  padding: 11px 0;
}

.contact-item:nth-child(even) {
  background: #fafafa;
}

.contact-item a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.85);
}

.contact-item h1,
.contact-item p,
.contact-item a {
  margin-bottom: 0;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
}

.contact-item p,
.contact-item a {
  margin-left: 5px;
}

.contact-item h1 {
  margin-left: 8px;
}

/* .contact-item-info {
  border: 1px black solid;
} */

.contact-item-info.name {
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  min-width: 225px;
}

.contact-item-info.phone {
  -webkit-flex: 0 0 20%;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  min-width: 175px;
}

.contact-item-info.email {
  -webkit-flex: 0 0 30%;
      -ms-flex: 0 0 30%;
          flex: 0 0 30%;
  min-width: 275px;
}

.department-item footer {
  padding: 0 17px;
  margin-bottom: 10px;
}

/* ============================================================================================== */
/* CONTACT MODAL STYLES */
/* ============================================================================================== */
.modal-form .ant-btn-circle.upload-img-circle-btn,
.modal-form .ant-avatar.ant-avatar-lg {
  width: 209px;
  height: 209px;
}

.modal-form .ant-avatar.ant-avatar-lg {
  box-shadow: 0 0 8px grey;
}

.modal-form .ant-btn-circle.upload-img-circle-btn {
  font-size: 12px;
  color: rgba(53, 53, 53, 0.65);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.modal-form .large-anticon svg {
  width: 33px;
  height: 33px;
}

.modal-form .upload-centered {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  /* justify-content: center; */
  margin-bottom: 10px;
}

.modal-form .upload-centered .ant-btn {
  margin-top: 10px;
}

.modal-form .requiredInput > .ant-form-item-control {
  width: 100%;
}

.modal-form .ant-avatar.blur > img {
}

/* ============================================================================================== */
/* SEARCH INPUT STYLES */
/* ============================================================================================== */
.header-search-items {
  position: absolute;
  right: 25px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.content-search-items {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 18px 0;
}

.search-input {
  margin-right: 15px;
}

.search-input-icon {
  position: absolute;
  right: 10px;
}

.content-search-items .right-aligned {
  position: absolute;
  right: 0;
}
.google-calendar {
  padding: 2em;
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.edit-button {
  
  /*position: absolute;
  right: 10px;*/
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 1em;

}

