.logo-box {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
}

.logo {
  width: auto;
  height: 100%;
}

.site-layout .site-layout-background {
  background: #fff;
}
