/* ============================================================================================== */
/* GENERAL LAYOUT STYLES */
/* ============================================================================================== */

.page-container {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.vertical-fill-layout {
  padding: 0 24px;
  flex: 1;
}

/* ============================================================================================== */
/* HEADER LAYOUT STYLES */
/* ============================================================================================== */
.page-header-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 98px;
  padding: 16px 24px;
  background-color: white;
}

.page-header-main-items {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-header-main-items h1 {
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.page-header-interactive-items .ant-input-affix-wrapper,
.page-header-interactive-items button {
  margin-left: 8px;
  margin-right: 0;
}

/* ============================================================================================== */
/* CONTENT CARD/CONTAINER STYLES */
/* ============================================================================================== */

.content-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 24px 0 0 0;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
}

.content-card.img-banner {
  flex: 0 0 auto;
  height: 200px;
}

.img-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.img-banner h1 {
  position: absolute;
  bottom: 0px;
  left: 24px;
  color: white;
  font-weight: 500;
  font-size: 36px;
  margin-bottom: 0;
  /* text-shadow: 0 0 5px black; */
}

.img-banner button {
  position: absolute;
  top: 10px;
  right: 24px;
  color: grey;
  font-size: 14px;
}

.img-banner-mask {
  position: absolute;
  background-color: rgba(105, 105, 105, 0.5);
  width: 100%;
  height: 100%;
}

.img-banner img {
  /* width: 100%; */
  /* flex-shrink: 1; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ============================================================================================== */
/* CONTENT LAYOUTS AND STYLES */
/* ============================================================================================== */
.content-card-header {
  padding: 24px 24px 0 24px;
  border-bottom: 2px solid #f0f0f0;
}

.content-card-header h1 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.content-card-header h1.bold {
  font-weight: 500;
}

.content-card-header .header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.content-card ul {
  list-style-type: none;
  padding: 0;
}

.content-card-list ul,
ul.content-card-list {
  margin: 0 24px;
  padding: 0;
  list-style-type: none;
}
