/* ============================================================================================== */
/* GENERAL CONTACT STYLES*/
/* ============================================================================================== */
.contact-item:last-child {
  margin-bottom: 12px;
}

/* ============================================================================================== */
/* CONTACT LIST AND ITEM STYLES*/
/* ============================================================================================== */
.contact-item {
  width: 100%;
}

.contact-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #f0f0f0;
  margin: 0 24px;
  padding: 11px 0;
}

.contact-item:nth-child(even) {
  background: #fafafa;
}

.contact-item a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.85);
}

.contact-item h1,
.contact-item p,
.contact-item a {
  margin-bottom: 0;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
}

.contact-item p,
.contact-item a {
  margin-left: 5px;
}

.contact-item h1 {
  margin-left: 8px;
}

/* .contact-item-info {
  border: 1px black solid;
} */

.contact-item-info.name {
  flex: 1 1 auto;
  min-width: 225px;
}

.contact-item-info.phone {
  flex: 0 0 20%;
  min-width: 175px;
}

.contact-item-info.email {
  flex: 0 0 30%;
  min-width: 275px;
}

.department-item footer {
  padding: 0 17px;
  margin-bottom: 10px;
}

/* ============================================================================================== */
/* CONTACT MODAL STYLES */
/* ============================================================================================== */
.modal-form .ant-btn-circle.upload-img-circle-btn,
.modal-form .ant-avatar.ant-avatar-lg {
  width: 209px;
  height: 209px;
}

.modal-form .ant-avatar.ant-avatar-lg {
  box-shadow: 0 0 8px grey;
}

.modal-form .ant-btn-circle.upload-img-circle-btn {
  font-size: 12px;
  color: rgba(53, 53, 53, 0.65);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-form .large-anticon svg {
  width: 33px;
  height: 33px;
}

.modal-form .upload-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  margin-bottom: 10px;
}

.modal-form .upload-centered .ant-btn {
  margin-top: 10px;
}

.modal-form .requiredInput > .ant-form-item-control {
  width: 100%;
}

.modal-form .ant-avatar.blur > img {
}
