hr.folder-hr{
  border: 1px solid #ABABAB;
  margin: 1em;
}

.resources-topbar{
  background: #fff;
  padding: 1em 1.71em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}
span.em4-light:last-of-type > a{
  color: #595959 !important;
}

.resources-topbar-right{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 1em;
}

@media only screen and (orientation: landscape) {
  .resources-search {
    width: 18em !important;
  }
}


.button-holder{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.button-holder > .ant-btn{
  margin: 0.25em !important;
}

.folder-blank > .ant-btn, .folder-blank > .add-blank{
  margin: 1em;
}
.folder-topbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f3f3f3;
  padding: 0 1.71em;
  padding-bottom: 0.89em;
  margin-bottom: 1.71em;
}

.folder-post{
  position: relative;
  overflow-x: scroll;
}

.folder-blank{
  display:flex;
  padding: 5em 0;
  flex-direction: column;
  align-items: center;
}

.post-topbar{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.folder-holder{
  display: flex;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 1.71em;
}

.post-editbar{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid #f3f3f3;
  padding: 0 1.71em;
  padding-bottom: 0.89em;
  margin-bottom: 1.71em;
}

.folder-editbar{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.folder-post{
  padding: 0 1.71em;
}

.folder-loading{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.move-dialog > .ant-modal-content > .ant-modal-body{
  padding: 0;
}
.move-modal-top>.anticon{
  padding-right: 1em;
}

.navpath-list{
  width: 100%;
  padding: 0.75em 1.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor:pointer;
}

.navpath-list-enabled:hover{
  background: #1890ff;
  font-weight: bold;
  color: white;
}
.navpath-list-disabled{
  background: #eee;
  color: #555;
  cursor: not-allowed;
}
.navpath-list-left{
  display: flex;
  align-items: center;
}
.navpath-list-empty{
  width: 100%;
  text-align: center;
  padding: 2em;
}
.navpath-list-left > .anticon-folder > svg{
  width: 2.5em !important;
  height: 2.5em !important;
  padding-right: 0.75em;
}
.navpath-list-enabled > .navpath-list-left > .anticon-folder > svg{
  color: #92ceff !important;
}
.navpath-list-disabled > .navpath-list-right > .anticon-right > svg{
  display:none;
}
.navpath-list-enabled > .navpath-list-right > .anticon-right > svg{
  color: #fff !important;
}

