.folder{
  margin: 0.5em;
  border-radius: 0.5em;
  padding: 0.25em 1em;
  width: calc( 25% - 1em);
  min-width: 5em;
  display: flex;
  align-items: center;
}
.folder-link{
  display: flex;
  align-items: center;
}
.folder-normal{
  color: black;
  border: 1px solid #C4C4C4;
}
.folder-normal:hover{
  background: #f3f3f3;
}

.folder-label{
  max-height: 1.5em;
  overflow: hidden;
  word-wrap: anywhere;
}
.folder-selected{
  background: #e9f0fd;
  border: 1px solid #3068cb;
  color: #3068cb;
  cursor: pointer;
}
.folder-selected:hover{
  -webkit-box-shadow: inset 0px 0px 100px 100px rgba(248,248,248,0.5);
  -moz-box-shadow: inset 0px 0px 100px 100px rgba(248,248,248,0.5);
  box-shadow: inset 0px 0px 100px 100px rgba(248,248,248,0.5);
}

.folder-logo-icon{
  line-height: 1;
  color: #92ceff !important;
}
.folder-logo-icon > .anticon-folder > svg{
  width: 2.5em !important;
  height: 2.5em !important;
  padding-right: 0.5em;
}

.folder-normal:hover, .folder-label:hover{
  color: black !important;
}

