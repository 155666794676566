/* ============================================================================================== */
/* HTML TEXT STYLES */
/* ============================================================================================== */
html,
body {
  font-family: "Roboto", sans-serif !important;
  background: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
} */

/* h4 {
  color: #999 !important;
  font-weight: 400 !important;
} */

.nav-container {
  margin: auto;
}
.nav-container svg {
  color: #fff;
}
a {
  text-decoration: none;
}

input {
}
